/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Culqui from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Platzi from "@components/sharedComponents/customersLogos/logos/Platzi.svg"
import Unifef from "@components/sharedComponents/customersLogos/logos/Unifef.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import Alfin from "@components/sharedComponents/customersLogos/logos/Alfin.svg"
import Civa from "@components/sharedComponents/customersLogos/logos/Civa.svg"
import G4S from "@components/sharedComponents/customersLogos/logos/G4S.svg"
import Ibr from "@components/sharedComponents/customersLogos/logos/Ibr.svg"
import Conexo from "@components/sharedComponents/customersLogos/logos/Conexo.svg"
import Eriza from "@components/sharedComponents/customersLogos/logos/3eriza.svg"
import Astara from "@components/sharedComponents/customersLogos/logos/Astara.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/toip.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/proveedores-telefonia-ip.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/auto-dialer-software.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/cold-calling-software.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/contact-center-omnicanal.jpg"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageFunctionalities/pageVoz/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageFunctionalities/pageVoz/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageFunctionalities/pageVoz/images/accordionLeft/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageFunctionalities/pageVoz/images/accordionLeft/imageFour.webp"
import ImageAccordionLeftFive from "@components/pageFunctionalities/pageVoz/images/accordionLeft/imageFive.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageVoz.jpg"

import { TitleUnderlineVozOne, TitleUnderlineVozTwo } from "@components/pageFunctionalities/components/intro/titleUnderlineVoz"
import imageStartVoz from "@components/pageFunctionalities/components/intro/images/imageStartVoz.webp"

//iconos poteciamos operaciones
import televentas from "@components/pageHome/operations/images/imageTwo.webp"
import telemarketing from "@components/pageHome/operations/images/imageThree.webp"
import cobranzas from "@components/pageHome/operations/images/imageSeven.webp"

// logos testimonios
import CulquiTes from "@components/sharedComponents/testimonies/images/logos/culqi.svg"
import IttsaBusTes from "@components/sharedComponents/testimonies/images/logos/ittsaBus.svg"
import SynlabTes from "@components/sharedComponents/testimonies/images/logos/synlab.svg"
import NetcallTes from "@components/sharedComponents/testimonies/images/logos/netcall.svg"
import EnseñaTes from "@components/sharedComponents/testimonies/images/logos/enseña.svg"

//foto testimonio desktop
import culqiDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/culqi.png"
import ittsaBusDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/ittsaBus.png"
import synlabDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/synlab.png"
import netcallDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/netcall.png"
import enseñaDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/enseña.png"

//foto testimonio desktop
import culqiMobile from "@components/sharedComponents/testimonies/images/fotosMobile/culqi.png"
import ittsaBusMobile from "@components/sharedComponents/testimonies/images/fotosMobile/ittsaBus.png"
import synlabMobile from "@components/sharedComponents/testimonies/images/fotosMobile/synlab.png"
import netcallMobile from "@components/sharedComponents/testimonies/images/fotosMobile/netcall.png"
import enseñaMobile from "@components/sharedComponents/testimonies/images/fotosMobile/enseña.png"


const titleStart = (
  <p>
    Accede a la mejor tecnología <TitleUnderlineVozOne underline="VoIP" />  que te permite escalar y automatizar tus campañas bajo un enfoque omnicanal
  </p>
)
let data = {
  start: {
    title: titleStart,
    textbody: (
      <p>
        Obtén llamadas entrantes y salientes de alta calidad, crea enrutamientos inteligentes, graba o escucha en tiempo real a tus agentes, adhiérelo a tus herramientas preferidas y utilízalo desde cualquier dispositivo.
        <br /><br />
        Maximiza el rendimiento de tu equipo con automatización de contactos, seguimiento omnicanal, y alertas personalizadas.
      </p>
    ),
    image: imageStartVoz,
    textbutton: "Habla con un experto",
    link: "/hablemos/",
  },
  customersDesktop: [
    Rimac,
    Culqui,
    Platzi,
    Unifef,
    Auna,
    Alfin,
    Civa,
    G4S,
    Ibr,
    Conexo,
    Eriza,
    Astara,
  ],
  customersTablet: {
    arrayOne: [Rimac, Culqui, Platzi, Unifef, Auna, Alfin],
    arrayTwo: [Civa, G4S, Ibr, Conexo, Eriza, Astara],
  },
  articles: [
    {
      image: article1,
      title: "ToIP: Qué es, ventajas y en qué se diferencia vs VoIP",
      textbody:
        "¿Quieres saber todo sobre el ToIP? En este artículo te explicamos a detalle toda la información que necesitas sobre esta tecnología.",
      link: "https://beexcc.com/blog/toip/",
      card: true,
    },
    {
      image: article2,
      title: "7 proveedores de telefonía IP que debes conocer para este 2024",
      textbody:
        "Recopilamos los mejores proveedores de telefonía IP que todo Contact Center debe considerar para el 2024.",
      link: "https://beexcc.com/blog/proveedores-telefonia-ip/",
      card: true,
    },
    {
      image: article3,
      title: "Auto Dialer Software: Top 5 de las plataformas para el 2024",
      textbody:
        "Entérate cuáles son las 5 plataformas que te ofrecen Auto Dialer Software. Así elegirás la mejor para tu empresa.",
      link: "https://beexcc.com/blog/auto-dialer-software/",
    },
    {
      image: article4,
      title: "14 preguntas para elegir el mejor software de Contact Center",
      textbody:
        "Te contamos qué consideraciones debes tener en cuenta para elegir el mejor software para call center y empieces a mejorar tus campañas.",
      link: "https://beexcc.com/blog/mejor-software-call-center/",
    },
    {
      image: article5,
      title: "Contact Center omnicanal: Lo que todo líder de equipo debe saber",
      textbody:
        "Si quieres aprender sobre un Contact Center omnicanal, en este artículo creamos una guía con toda la información esencial.",
      link: "https://beexcc.com/blog/contact-center-omnicanal/",
    },
  ],
  contacts: {
    title: "¡Potencia tus llamadas con nuestra tecnología Voip!",
    textbody: "Queremos ayudarte a potenciar tus estrategias de comunicación telefónica. Déjanos saber cuáles son tus necesidades y nuestros agentes se pondrán en contacto contigo.",
    href: "/hablemos/",
    btn: "Quiero una asesoría gratuita",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  accordionRight: [
    {
      title: "Integraciones con APIs",
      description: "<p>Sincroniza tu CRM y plataformas de gestión con nuestro sistema VoIP, unificando datos y comunicaciones en un solo lugar. Mejora la productividad de tu equipo y ofrece experiencias personalizadas que convierten prospectos en clientes leales.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "Autoservicio con IVR",
      description: "<p>Empodera a tus clientes con nuestro sistema de IVR avanzado, permitiéndoles resolver sus inquietudes de manera rápida y eficiente, 24/7. Reduce los tiempos de espera y libera a tus agentes para que se enfoquen en casos que realmente requieren atención personal.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Inteligencia artificial",
      description: "<p>Transforma la interacción con tus clientes utilizando IA. Desde asistentes virtuales hasta análisis de llamadas en tiempo real, ofrecemos las herramientas para que tu servicio al cliente sea más inteligente, eficaz y personalizado.</p>",
      image: ImageAccordionLeftThree,
    },
    {
      title: "Seguimiento con canales digitales",
      description: "<p>Asegura una experiencia omnicanal integrando canales digitales a tu estrategia de comunicación. Con nuestro sistema VoIP, ofrece a tus clientes la flexibilidad de contactarte cómo y cuándo quieran, mejorando significativamente su satisfacción.</p>",
      image: ImageAccordionLeftFour
    },
    {
      title: "Notificaciones y alertas automatizadas",
      description: "<p>Mantén a tus clientes informados y comprometidos con notificaciones y alertas automatizadas. Desde recordatorios de citas hasta alertas de pagos, nuestra solución mejora la comunicación y fomenta una relación más fuerte y positiva con tu audiencia.</p>",
      image: ImageAccordionLeftFive
    },
  ],
  bannerVideo: {
    title: "Llamadas VoIP: ¿Cómo mejorar su rendimiento?",
    description: "<p>Aprende cómo garantizar una calidad de sonido excepcional, minimizar las interrupciones y maximizar la eficiencia de tus llamadas.<br /><br />Nuestro VoIP specialist, Julio Guarniz, te proporciona valiosos consejos para mejorar el rendimiento de tus llamadas con esta tecnología.</p>",
    image: imageVideo
  },
  modules: [
    {
      tab: "Customer Experience",
      title: "Atención al cliente",
      img: televentas,
      link: "/atencion-al-cliente/",
      text:
        "Permite a los equipos de atención al cliente gestionar llamadas desde cualquier lugar, asegurando disponibilidad y continuidad. La integración con CRM y herramientas de soporte mejora el acceso a la información del cliente, permitiendo respuestas rápidas y personalizadas.",
      colorTab: "#FFD96E",
      background: "#ffebb7"
    },
    {
      tab: "Conversion",
      title: "Telemarketing",
      img: telemarketing,
      text:
        "Reduce costos operativos al eliminar la necesidad de infraestructura telefónica tradicional. Permite realizar más llamadas en menos tiempo, con una calidad de sonido superior y sin limitaciones geográficas, aumentando las oportunidades de venta.",
      link: "/marketing-ventas/",
      colorTab: "#AFC9F7",
      background: "#d7e4fb"
    },
    {
      tab: "Payment",
      title: "Cobranzas",
      img: cobranzas,
      link: "/software-cobranzas/",
      text:
        "Facilita la comunicación con clientes a nivel global a costos reducidos, mejorando la gestión de llamadas y el seguimiento de compromisos de pago. La posibilidad de integrar con sistemas de gestión de cobranzas automatiza procesos y optimiza el tiempo de los agentes.",
      colorTab: "#FFAB99",
      background: "#FFD5CC"
    },
  ],
  testimonies: [
    {
      image: CulquiTes,
      title: "Culqi aumentó en un 65% su contactabilidad las automatizaciones de Beex",
      link: "/casos-de-exito/culqi/",
      industrie: "Banca y finanzas",
      backgroundIndustrie: "#7B5095",
      backgroundFondo: "#F3DFFF",
      metrics: [
        {
          metric: "+65%",
          descripcion: "más leads contactados",
          color: "#FFB800",
        },
        {
          metric: "x8",
          descripcion: "ventas mensuales aumentaron",
          color: "#5831D8",
        },
        {
          metric: "x3",
          descripcion: "menos tiempo en recorrer bases",
          color: "#00CA56",
        },
      ],
    },
    {
      image: IttsaBusTes,
      title: "IttsaBus mejoró en un 22% la satisfacción de sus pasajeros al reprogramar viajes con Beex",
      link: "/casos-de-exito/ittsa-bus/",
      industrie: "Transportes",
      backgroundIndustrie: "#F79531",
      backgroundFondo: "#F7953133",
      metrics: [
        {
          metric: "95%",
          descripcion: "mmejor tiempo de respuesta",
          color: "#FFB800",
        },
        {
          metric: "22%",
          descripcion: "más satisfacción en viajes",
          color: "#5831D8",
        },
      ],
    },
    {
      image: SynlabTes,
      title: "SYNLAB resolvió el 20% de casos sin agentes y mejoró en un 95% su tiempo de respuesta",
      link: "/casos-de-exito/synlab/",
      industrie: "Salud",
      backgroundIndustrie: "#092F57",
      backgroundFondo: "#092F5733",
      metrics: [
        {
          metric: "95%",
          descripcion: "mejor tiempo de respuesta",
          color: "#FFB800",
        },
        {
          metric: "20%",
          descripcion: "tickets sin usar agentes",
          color: "#5831D8",
        },
        {
          metric: "20 seg",
          descripcion: "menos en respuesta telefónica",
          color: "#00CA56",
        },
      ],
    },
    {
      image: NetcallTes,
      title: "Netcall duplicó sus ventas mensuales con el marcador predictivo de Beex",
      link: "/casos-de-exito/netcall-peru/",
      industrie: "Contact Center & BPO",
      backgroundIndustrie: "#EC4600",
      backgroundFondo: "#EC460033",
      metrics: [
        {
          metric: "+25%",
          descripcion: "más contactabilidad",
          color: "#FFB800",
        },
        {
          metric: "+20%",
          descripcion: "mayor tasa de ocupación",
          color: "#5831D8",
        },
        {
          metric: "x2",
          descripcion: "duplicaron sus ventas",
          color: "#00CA56",
        },
      ],
    },
    {
      image: EnseñaTes,
      title: "Enseña Perú aumentó en un 50% su contactabilidad con potenciales donantes gracias a Beex ",
      link: "/casos-de-exito/ensena-peru/",
      industrie: "ONG",
      backgroundIndustrie: "#FF0028",
      backgroundFondo: "#FF002833",
      metrics: [
        {
          metric: "40%",
          descripcion: "aumentaron sus donantes",
          color: "#FFB800",
        },
        {
          metric: "+50%",
          descripcion: "mejoró su contactabilidad",
          color: "#5831D8",
        },
      ],
    },
      ],
}

export default data
